// import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
// import HomeIcon from '@material-ui/icons/Healing';

const siteDomain = '//shop.facing.tech';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={siteDomain}>
        CureLife 良愈
      </Link>{' '}
      <span role="img" aria-label="CureLife">
        ❤️
      </span>
      {/* <HomeIcon /> */} {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  footerContact: {
    color: '#3f51b5',
    textDecoration: 'none',
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          <span role="img" aria-label="联系方式">
            📱
          </span>
          <a className={classes.footerContact} href="tel:+8613858687898">
            13858687898
          </a>
          {/* 联系方式：+(86) 18616267626 */}
          {'  |  '}
          <span role="img" aria-label="电子邮箱">
            📧
          </span>
          <a
            className={classes.footerContact}
            href="mailto:admin@facing.tech?cc=eric@facing.tech, sales@facing.tech&bcc=boss@facing.tech&subject=Great%20News&body=Online shop is go live"
          >
            admin@facing.tech
          </a>
        </Typography>
        {/* <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          <span role="img" aria-label="友情链接">
            🔗
          </span>
          友情链接：
        </Typography> */}
        <Copyright />
      </footer>
      {/* End footer */}
    </>
  );
}

Footer.propTypes = {};
Footer.defaultProps = {};
