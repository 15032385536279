import React from 'react';
// { useEffect, useState, useCallback }
// import PropTypes from 'prop-types';
import {} from './redux/hooks';

export default function List() {
  return (
    <div className="product-list">
      Component content: product/List
    </div>
  );
};

List.propTypes = {};
List.defaultProps = {};
