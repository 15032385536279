// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { List, Detail } from './';

export default {
  path: 'product',
  childRoutes: [
    { path: 'list', component: List },
    { path: 'view/:id', component: Detail },
    { path: 'edit/:id', component: Detail },
  ],
};
