import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Header, Footer, ScrollTop } from '../common/';
import products from '../common/products.json';
products.sort((a, b) => a.order - b.order);
// console.log(products);

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    cursor: 'pointer',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  pos: {
    marginBottom: 12,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function IndexPage(props) {
  const classes = useStyles();
  const [codeDialogOpen, setCodeDialogOpen] = useState(false);
  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h3" align="center" color="textPrimary" gutterBottom>
              致敬生命!
              {/* <span role="img" aria-label="Life">
                ❤️
              </span>{' '}
              To Life */}
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              万物之中，希望至美。 Hope is a good thing.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCodeDialogOpen(true);
                    }}
                  >
                    联系我们
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Button variant="outlined" color="primary">
                    关于我们
                  </Button>
                </Grid> */}
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {products.map(product => (
              <Grid item key={'product-' + product.id} xs={12} sm={6} md={4}>
                <Card
                  className={classes.card}
                  onClick={() => {
                    console.log(`点击Card查看产品 ${product.id}`);
                    // props.history.push(`/product/view/${product.id}`);
                  }}
                >
                  <CardMedia
                    className={classes.cardMedia}
                    image={'/images/' + product.image}
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {product.name}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      {product.spec}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {product.desc}
                    </Typography>
                    {/* <Typography>{product.spec}</Typography> */}
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => {
                        // console.log(`点击查看产品 ${product.id}`);
                        props.history.push(`/product/view/${product.id}`);
                      }}
                    >
                      查看
                    </Button>
                    {/* <Button size="small" color="primary">
                      Edit
                    </Button> */}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      <Footer />
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      <Dialog
        open={codeDialogOpen}
        onClose={() => {
          setCodeDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'微信扫一扫二维码'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <img
              src={'/images/wxcode.png'}
              alt={'微信二维码'}
              style={{ maxWidth: '700px', width: '100%', minWidth: '250px' }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setCodeDialogOpen(false);
            }}
            color="primary"
            autoFocus
          >
            关闭
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
