import React from 'react';
// import PropTypes from 'prop-types';
import {} from './redux/hooks';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Header, Footer, ScrollTop } from '../common/';
import products from '../common/products.json';
// console.log(products);

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  productContent: {
    backgroundColor: theme.palette.background.paper,
    padding: '64px 0px 30px',
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  actionButtons: {
    marginTop: '20px',
    marginBottom: '60px',
  },
  productGrid: {
    paddingTop: '30px',
    paddingBottom: '50px',
  },
  detailTitle: {
    marginTop: '5px',
  },
  detailSpec: {
    // color: 'rgba(0, 0, 0, 0.54)',
    // color: '#3f51b5',
    color: 'rgb(220, 0, 78)',
    fontSize: '1rem',
    fontWeight: '400',
    marginLeft: '12px',
  },
  detailCompany: {
    marginTop: '10px',
    marginBottom: '10px',
    fontSize: '1rem',
  },
  detailDesc: {
    margin: '10px 5px 20px',
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function Detail(props) {
  const classes = useStyles();
  const { id } = useParams();
  const product = products.filter(p => p.id.toString() === id);
  // console.log(product);

  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <main>
        <div className={classes.productContent}>
          <Container maxWidth="sm">
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              <span role="img" aria-label="Detail">
                🔎{' '}
              </span>
              产品 {id} 的详细内容
            </Typography>
          </Container>
        </div>
        <Container className={classes.productGrid} maxWidth="md">
          <Grid container spacing={4}>
            {product && product.length > 0 && (
              <div style={{ textAlign: 'center', width: '100%' }}>
                {/* <h2>{product[0].name}</h2>
                <h3>{product[0].desc}</h3> */}
                <Typography className={classes.detailTitle} variant="h5" component="h2">
                  {product[0].name}
                  <span className={classes.detailSpec}> {product[0].spec}</span>
                </Typography>
                {product[0].company && (
                  <Typography className={classes.detailCompany} color="textSecondary">
                    <strong>生产商: </strong>
                    {product[0].company}
                  </Typography>
                )}
                <Typography className={classes.detailDesc} variant="body2" component="p">
                  {product[0].desc}
                </Typography>
                <p>
                  <img
                    src={'/images/' + product[0].image}
                    alt={product[0].name}
                    style={{ maxWidth: '700px', width: '100%', minWidth: '250px' }}
                  />
                </p>
                <p></p>
                {/* <p>{JSON.stringify(product[0])}</p> */}
              </div>
            )}
          </Grid>
        </Container>
        <div className={classes.actionButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  console.log(`返回首页`);
                  props.history.push(`/`);
                }}
              >
                返回首页
              </Button>
            </Grid>
            {/* <Grid item>
                  <Button variant="outlined" color="primary">
                    关于我们
                  </Button>
                </Grid> */}
          </Grid>
        </div>
      </main>
      <Footer />
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}

Detail.propTypes = {};
Detail.defaultProps = {};
